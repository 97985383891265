import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SharedService } from '../services/shared/shared.service';

@Component({
  selector: 'app-schedule-calender',
  templateUrl: './schedule-calender.page.html',
  styleUrls: ['./schedule-calender.page.scss'],
})
export class ScheduleCalenderPage implements OnInit {
  options: any;
  selectedDate: any;
  dataType: any = 'js-date'; //? 'string' | 'js-date' | 'moment' | 'time' | 'object'
  constructor(
    private sharedService: SharedService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }
  async closeModal(data?: any) {
    this.modalController.dismiss(data || undefined);
  }

  getDate(event: any) {
    this.selectedDate = event;
    console.log("selectedDate", this.selectedDate);
  }

  async saveCalender() {
    console.log("selectedDate", this.selectedDate);
    if (this.selectedDate) {
      const weekdayName = new Date(this.selectedDate).toLocaleString("default", { weekday: "long" });
      console.log("weekdayName", weekdayName);
      const data = {
        date: this.selectedDate,
        dayName: weekdayName
      }
      this.closeModal(data);
    }
    else {
      this.closeModal();
    }
  }

}
