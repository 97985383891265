export const environment = {
    production: true,
    firebase: {
    apiKey: "AIzaSyCXury9kyVuELnRfB6mmhaby_bYeF4JF5A",
    authDomain: "bwi-1199.firebaseapp.com",
    projectId: "bwi-1199",
    databaseURL: "https://bwi-1199.firebaseio.com",
    storageBucket: "bwi-1199.appspot.com",
    messagingSenderId: "331208538051",
    appId: "1:331208538051:web:14eb6e78e96ee841e86f76",
    measurementId: "G-YWN3GHJFT9",
    },
    shopProductsLimit: 50,
    timeToUpdateAgentLocation: 30000,
    deliveryBikeurl: 'assets/img/delivery-bike.png',
    destinationMarkerUrl: 'assets/img/map-marker.png',
    razorpay: {
      description: "Surprise India Payment",
      currency: 'INR',
      image: 'https://surpriseindia.in/assets/img/admin-pic.png',
      theme: {
        color: '#ea1825'
      },
      useCheckoutJs: false
    },
    paytm: {
      CHANNEL_ID: 'WAP',
      WEBSITE: 'DEFAULT',
      INDUSTRY_TYPE_ID: 'Retail',
      ENVIRONMENT: 'production'
    },
    envPaytmActive: false,
    storeName: "Surprise India",
    supportPhone: '1234567890',
    scrollLimit:20,
    priceReqFeature:false,
    appSharingImg:'https://surpriseindia.in/assets/img/admin-pic.png',
    deliveryFeature: false,
    ALGOLIA_APP_ID: 'DEYL6OZL2B',
    ALGOLIA_APP_KEY: 'd7f75c2a6d20c84da946c70a08039dbc',
    isDeliveryBasedKm: false,
    orderIdPrefix: 'ORD',
    defaultCountryCode: '+91',
    currencyCode: 'INR',
    taxType: 'GST',
    socialSigin: false,
    defaultProductView: 'list',
    webClientId:'331208538051-pk0jsi5s5elkp5khi19u8snn201u9ufh.apps.googleusercontent.com',
    productSharing: true,
    phoneLength: 10,
    showFooterLogo: true,
    openAlgoliaSearch:false,
    LOGGLY_API_KEY: 'dd80a702-e3cf-4633-9d38-5ee92fe68193',
    priceForRetail: false,
    referralFeature: false,
    subscriptionFeature: false,
    RFQFeature: false,
    usersScrollLimit: 20,
    limitedTimeDeal: false,
    productRatings: false,
    multiRegion: false,
    multiVendor: false,
    membership: false,
    isDeliveryBasedOnWeight: false,
    userSignature: false,
    freeTrial: false,
    isBwiClient: false,
    lockSystem: false,
    broadcastLimit:24,
    imagesLimit: 5,
    orderUploadedDocImgsLimit: 5,
    resellingFeature: false,
    shareCatalogue: false,
    qtyLeft: 5,
    allOrderStatuses: ['Pending', 'Rejected', 'Confirmed', 'Cancelled', 'Dispatched', 'Delivered', 'Returned'],
    appAccessOnApproval: false,
    pickDrop: false,
    cashfree: {production: false},
    appointmentFeature: false
  };
