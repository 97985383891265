import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SharedService } from '../services/shared/shared.service';
export class ScheduleCalenderPage {
    constructor(sharedService, modalController) {
        this.sharedService = sharedService;
        this.modalController = modalController;
        this.dataType = 'js-date'; //? 'string' | 'js-date' | 'moment' | 'time' | 'object'
    }
    ngOnInit() {
    }
    closeModal(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modalController.dismiss(data || undefined);
        });
    }
    getDate(event) {
        this.selectedDate = event;
        console.log("selectedDate", this.selectedDate);
    }
    saveCalender() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("selectedDate", this.selectedDate);
            if (this.selectedDate) {
                const weekdayName = new Date(this.selectedDate).toLocaleString("default", { weekday: "long" });
                console.log("weekdayName", weekdayName);
                const data = {
                    date: this.selectedDate,
                    dayName: weekdayName
                };
                this.closeModal(data);
            }
            else {
                this.closeModal();
            }
        });
    }
}
