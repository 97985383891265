/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-calender.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../node_modules/ion2-calendar/dist/components/calendar.component.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "ion2-calendar/dist/components/calendar.component";
import * as i7 from "ion2-calendar/dist/services/calendar.service";
import * as i8 from "./schedule-calender.page";
import * as i9 from "../services/shared/shared.service";
var styles_ScheduleCalenderPage = [i0.styles];
var RenderType_ScheduleCalenderPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleCalenderPage, data: {} });
export { RenderType_ScheduleCalenderPage as RenderType_ScheduleCalenderPage };
export function View_ScheduleCalenderPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "ion-toolbar", [["class", "toolbar"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-title", [["text-center", ""]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(6, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Schedule Calender"])), (_l()(), i1.ɵeld(8, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(9, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "ion-icon", [["name", "close-circle"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(13, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 7, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(15, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 5, "div", [["class", "calenderDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "flaticon-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "flaticon-next"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "ion-calendar", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.getDate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CalendarComponent_0, i4.RenderType_CalendarComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.CalendarComponent]), i1.ɵdid(21, 114688, null, 0, i6.CalendarComponent, [i7.CalendarService], { format: [0, "format"], type: [1, "type"], options: [2, "options"] }, { change: "change" }), (_l()(), i1.ɵeld(22, 0, null, null, 4, "ion-footer", [], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(23, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(24, 0, null, 0, 2, "ion-button", [["class", "footer-button btn-1"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCalender() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(25, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" select "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "close-circle"; _ck(_v, 13, 0, currVal_0); var currVal_1 = "YYYY-MM-DD"; var currVal_2 = _co.dataType; var currVal_3 = _co.options; _ck(_v, 21, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "block"; _ck(_v, 25, 0, currVal_4); }, null); }
export function View_ScheduleCalenderPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-schedule-calender", [], null, null, null, View_ScheduleCalenderPage_0, RenderType_ScheduleCalenderPage)), i1.ɵdid(1, 114688, null, 0, i8.ScheduleCalenderPage, [i9.SharedService, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduleCalenderPageNgFactory = i1.ɵccf("app-schedule-calender", i8.ScheduleCalenderPage, View_ScheduleCalenderPage_Host_0, {}, {}, []);
export { ScheduleCalenderPageNgFactory as ScheduleCalenderPageNgFactory };
