<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>Schedule Calender</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="calenderDiv">
    <i class="flaticon-back"></i>
    <i class="flaticon-next"></i>
    <ion-calendar (change)="getDate($event)" [options]="options" [type]="dataType"
      [format]="'YYYY-MM-DD'">
    </ion-calendar>
  </div>

</ion-content>

<ion-footer>
  <ion-button (click)="saveCalender()" expand="block" class="footer-button btn-1">
    select
  </ion-button>
</ion-footer>